const _models = Symbol('Models');
const _geoBuffer = Symbol('GeoBuffer');
const _geoBufferCb = Symbol('GeoBufferCb');
const _geoMatrix = Symbol('GeoMatrix');
const _finalCallback = Symbol('FinalCallback');
const _singleCallback = Symbol('SingleCallback');
const _bufferStack = Symbol('BufferStack');

const ValidationError = require('../../../../views/modules/common/errors/validation_error');

class Util {
  constructor() {
    this[_models] = $('[ingtech-model]').detach().toArray()
      .reduce((models, template) => (models[template.attributes['ingtech-model'].value] = $(template).html(), models), {});

    const selectCheckBox = function () {
      return {
        name: 'selectCheckBox',
        ...selectCheckBox
      };
    }

    Object.assign(selectCheckBox, {
      data: null, orderable: false, className: 'noVis', render: function (data, type, rowData, parameters) {
        let checked = data.checked;
        let disabled;

        if (checked === undefined) {
          const dt = $.fn.dataTable.Api(parameters.settings);
          const row = dt.row(parameters.row);

          checked = row.selected();
        }

        return `
          <label class="i-checks i-checks-dark">
            <input type="checkbox" class="row-select"${checked ? ' checked' : ''}${disabled ? ' disabled' : ''}>
            <i></i>
          </label >
        `;
      }
    });

    this.dataTableCheckboxDom = selectCheckBox;



    this[_geoBuffer] = null
    this[_geoBufferCb] = []
    this[_geoMatrix] = {}
    this[_singleCallback] = {}
    this[_bufferStack] = {}
  }

  sleep(time, arg) {
    return new Promise(resolve => {
      setTimeout(resolve, time, arg);
    });
  }

  format(str) {
    let args = arguments;

    return str.replace(/{(\d+)}/g, (match, number) =>
      typeof args[parseInt(number) + 1] != 'undefined' ? args[parseInt(number) + 1] : match);
  }

  i18nFormat(args, sanitize) {
    args = [...args];
    let prop = i18n.find(args.shift(), true, true);

    if (sanitize) args = args.map(text => this.sanitizeHTML(text));

    if (prop) {
      return i18n[prop](...args);
    } else {
      return args.join(' ');
    }
  }

  formatPhone(str = '') {
    return str.replace(/[\+]?[(]?([0-9]{3})[)]?[-\s\.]?([0-9]{3})[-\s\.]?([0-9]{4})/, '($1) $2-$3');
  }

  haversine(location1, location2) {
    var R = 6371000;

    let lat1 = location1[0], lng1 = location1[1],
      lat2 = location2[0], lng2 = location2[1];

    var latRad1 = this.degToRad(lat1);
    var latRad2 = this.degToRad(lat2);
    var deltaLat = this.degToRad(lat2 - lat1);
    var deltaLng = this.degToRad(lng2 - lng1);

    var a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(latRad1) * Math.cos(latRad2) *
      Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  }

  /**
   * Returns the bearing between two locations
   * (bearing is the angle between the north direction and the direction to the target or destination point)
   * 
   * @param {number[]} location1 [lng, lat]
   * @param {number[]} location2 [lng, lat]
   * @returns {number} bearing in radians
   */
  bearing(location1, location2) {
    let R = 6371000;

    let lat1 = this.degToRad(location1[1]), lng1 = this.degToRad(location1[0]),
      lat2 = this.degToRad(location2[1]), lng2 = this.degToRad(location2[0]);

    let y = Math.sin(lng2 - lng1) * Math.cos(lat2);
    let x = Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1);

    return Math.atan2(y, x);
  }

  newPointFromDirection(location, direction, distance) {
    let R = 6371000;

    let lat1 = this.degToRad(location[1]), lng1 = this.degToRad(location[0]);

    let lat2 = Math.asin(Math.sin(lat1) * Math.cos(distance / R) +
      Math.cos(lat1) * Math.sin(distance / R) * Math.cos(direction));
    let lng2 = lng1 + Math.atan2(Math.sin(direction) * Math.sin(distance / R) * Math.cos(lat1),
      Math.cos(distance / R) - Math.sin(lat1) * Math.sin(lat2));

    let lngDeg = this.round(this.radToDeg(lng2), 6);
    let latDeg = this.round(this.radToDeg(lat2), 6);

    return [lngDeg, latDeg];
  }


  hueToRgb(p, q, t) {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  }

  hslToRgb(h, s, l) {
    var r, g, b;

    if (s == 0) {
      r = g = b = l; // achromatic
    } else {
      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;
      r = this.hueToRgb(p, q, h + 1 / 3);
      g = this.hueToRgb(p, q, h);
      b = this.hueToRgb(p, q, h - 1 / 3);
    }

    return {
      [0]: Math.round(r * 255),
      [1]: Math.round(g * 255),
      [2]: Math.round(b * 255),
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255)
    };
  }

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  rgbToHex(r, g, b) {
    return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  hslToHex(h, s, l, a) {
    let c = this.hslToRgb(h, s, l);
    return this.rgbToHex(c[0], c[1], c[2]); // + componentToHex(Math.round((Math.min(a, 1) || 1)*255))
  }

  hexToRgb(hex, cssFormat = false) {
    var aRgbHex = hex.replace('#', '').match(/.{1,2}/g);
    var aRgb = [
      parseInt(aRgbHex[0], 16),
      parseInt(aRgbHex[1], 16),
      parseInt(aRgbHex[2], 16),
      aRgbHex[3] ? parseInt(aRgbHex[3], 16) / 255 : 1
    ];

    if (cssFormat) {
      return `rgba(${aRgb[0]}, ${aRgb[1]}, ${aRgb[2]}, ${aRgb[3]})`;
    } else {
      return {
        [0]: aRgb[0],
        [1]: aRgb[1],
        [2]: aRgb[2],
        [3]: aRgb[3],
        r: aRgb[0],
        g: aRgb[1],
        b: aRgb[2],
        a: aRgb[3]
      };
    }
  }

  degToRad(degrees) {
    return degrees * Math.PI / 180;
  }

  radToDeg(radient) {
    return radient / Math.PI * 180;
  }

  round(number, decimal = 0, toFixed = false) {
    let roundedNumber = Math.round(number * Math.pow(10, decimal || 0)) / Math.pow(10, decimal || 0);

    return toFixed ? roundedNumber.toFixed(decimal) : roundedNumber;
  }

  clamp(number, min, max) {
    return Math.min(Math.max(number, Math.min(min, max)), Math.max(min, max));
  }

  inRange(number, min, max) {
    return number >= Math.min(min, max) && number <= Math.max(min, max);
  }

  clone(obj, recursive = false) {
    if (obj instanceof Array) {
      if (recursive) {
        return obj.map(sObj => this.clone(sObj, true));
      } else {
        return obj.slice(0);
      }
    }
    else if (obj instanceof Object) {
      if (typeof obj.clone == 'function') {
        return obj.clone();
      } else {
        let newObj = Object.assign({}, obj);

        if (recursive) {
          for (let [key, value] of Object.entries(newObj)) {
            newObj[key] = this.clone(value, true);
          }
        }

        return newObj;
      }
    }

    return obj;
  }


  /**
   *
   * @template T
   * @template {keyof T} K
   * 
   * @overload
   * @param {T[]} obj
   * @param {K[]} keys
   * @return {Pick<T, K>[]}
   *//**
  * @overload
  * @param {T[]} obj
  * @param {K[]} keys
  * @return {Pick<T, K>[]}
  *
  * @memberof Util
  */
  pick(obj, keys) {
    if (obj instanceof Array) {
      return this.clone(obj).map(sObj => this.pick(sObj, keys));
    }
    else if (obj instanceof Object) {
      if (obj.dataValues instanceof Object) {
        return this.pick(obj.dataValues, keys);
      } else {
        let newObj = {};

        for (let key of keys) {
          if (typeof key == 'string') {
            newObj[key] = obj[key];
          } else if (key instanceof Array && key.length == 2 && typeof key[0] == 'string' && key[1] instanceof Array) {
            newObj[key[0]] = this.pick(obj[key[0]], key[1]);
          } else {
            throw new TypeError();
          }
        }

        return newObj;
      }
    }

    return obj;
  }


  /**
   *
   *
   * @template T
   * @template {keyof T} K
   * @param {T[]} obj
   * @param {K} key
   * @return {T[K][]} 
   * @memberof Util
   */
  pickOne(obj, key) {
    if (obj instanceof Array) {
      return obj.map((sObj) => this.pickOne(sObj, key));
    } else if (obj instanceof Object) {
      return obj[key];
    }

    return null;
  }

  /**
   * @template T
   * @template {keyof T[]} K
   * 
   * @param {T | T[]} obj 
   * @param {K} keys
   * @returns {Omit<T, K> | Omit<T, K>[]}
   */
  omit(obj, keys) {
    if (obj instanceof Array) {
      return this.clone(obj).map(sObj => this.omit(sObj, keys));
    }
    else if (obj instanceof Object) {
      if (obj.dataValues instanceof Object) {
        return this.omit(obj.dataValues, keys);
      } else {
        let newObj = {};

        for (let [key, value] of Object.entries(obj)) {
          if (!keys.includes(key)) {
            newObj[key] = value;
          }
        }

        return newObj;
      }
    }

    return obj;
  }

  /**
   * @template T
   * @template {keyof T} K
   * 
   * @param {T[]} arr 
   * @param {K} key 
   * @returns {Record<T[K], T>}
   */
  arrayByKey(arr, key = 'id') {
    if (!(arr instanceof Array)) throw TypeError();

    return arr.reduce((dictionary, obj) => {
      if (obj instanceof Object && typeof obj[key] != 'undefined' && obj[key] != null) {
        dictionary[obj[key]] = obj;
      }

      return dictionary;
    }, {});
  }

  regroupByKey(arr, key = 'id') {
    if (!(arr instanceof Array)) throw TypeError();

    return arr.reduce((dictionary, obj) => {
      if (obj instanceof Object && typeof obj[key] != 'undefined' && obj[key] != null) {
        dictionary[obj[key]] = dictionary[obj[key]] || [];
        dictionary[obj[key]].push(obj);
      }

      return dictionary;
    }, {});
  }

  invert(obj) {
    const ret = {};
    Object.keys(obj).forEach(key => {
      ret[obj[key]] = key;
    });
    return ret;
  }

  getProfilePicture(user, size = null, id = '') {
    let source, image;

    if (user.photo) {
      source = `data:image/jpeg;base64, ${user.photo}`;
    } else {
      if (user.sex == 'Male' || user.sex == 'male') {
        source = '/imgs/male.jpg';
      } else {
        source = '/imgs/female.jpg';
      }
    }

    if (parseInt(size) > 0) {
      image = `<img id="${id}" class="profile" width="${size}" height="${size}" src="${source}" />`;
    } else {
      image = `<img id="${id}" class="profile" src="${source}" />`;
    }

    return image;
  }

  // this.standardDeviation = function (values, avg) {
  //   var avg = avg || average(values);

  //   var squareDiffs = values.map(value => Math.pow(value - avg, 2));
  //   var avgSquareDiff = average(squareDiffs);

  //   var stdDev = Math.sqrt(avgSquareDiff);
  //   return stdDev;
  // }

  // this.average = function (data) {
  //   var sum = data.reduce((acc, cur) => acc + cur);

  //   return sum / data.length;
  // }

  /**
   * 
   * @param {[value: number, weight: number][]} values 
   * @returns {number}
   */
  weightedAverage(values) {
    let sum = 0;
    let weightSum = 0;

    values.forEach(([value, weight]) => {
      sum += value * weight;
      weightSum += weight;
    })

    return sum / weightSum
  }

  // this.weightedStandardDeviation = function (data, wAvg) {
  //   wAvg = wAvg || weightedAverage(data);

  //   let squareDiff = 0;
  //   let totalWeight = 0;
  //   let notZeroWeights = 0;

  //   data.forEach(v => {
  //     let val = v[0];
  //     let weight = v[1];

  //     squareDiff += weight * Math.pow(val - wAvg, 2)

  //     totalWeight += weight;

  //     notZeroWeights += weight > 0 ? 1 : 0;
  //   })

  //   return Math.sqrt(squareDiff / (((notZeroWeights - 1) / notZeroWeights) * totalWeight))
  // }

  // Thanks https://stackoverflow.com/questions/3169786/clear-text-selection-with-javascript
  removeSelection() {
    if (window.getSelection) {
      if (window.getSelection().empty) {  // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {  // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {  // IE?
      document.selection.empty();
    }
  }

  download(url, filename) {
    let a = $('<a>').attr({
      href: url,
      download: filename || url.substr(url.lastIndexOf('/') + 1),
      style: 'display: none;'
    });

    a.get(0).click();
  }

  downloadCSV(data, filename) {
    let url = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(data); //URL.createObjectURL(file);
    this.download(url, filename);
  }

  downloadBuffer(buffer, filename) {
    let arr = new Uint8Array(JSON.parse(buffer));
    let file = new Blob([arr]);
    let url = URL.createObjectURL(file);
    this.download(url, filename);
  }

  downloadBlobBuffer(buffer, filename){
    let file = buffer instanceof Blob ? buffer : new Blob([buffer], { type: 'application/pdf' });
    let url = URL.createObjectURL(file);
    this.download(url, filename);
  }

  bufferToBlob(buffer) {
    return new Blob([new Uint8Array(buffer.data)]);
  }


  toArray(object, ref) {
    let arr = [];

    for (let key in object) {
      let value = object[key];

      if (ref) {
        value[ref] = key;
      }

      arr.push(value);
    }

    return arr;
  }


  sortObjects(arr, name, desc) {
    name = name || 'name';

    return arr.sort((a, b) => {
      let res = 0;

      if (isNaN(a[name]) || isNaN(b[name])) {
        if (a[name] > b[name]) {
          res = 1;
        } else if (a[name] < b[name]) {
          res = -1;
        }
      } else {
        res = a[name] - b[name];
      }

      return res * (desc ? -1 : 1);
    });
  }

  encodeObject(obj, prefix = '') {
    return Object.entries(obj).map(([key, value]) => {
      if (value === undefined) {
        return;
      }

      if (value instanceof Array) {
        return this.encodeObject([...value.entries()].reduce((obj, [i, val]) => (obj[`[${i}]`] = val, obj), {}), prefix + key);
      } else if (value && typeof value == 'object') {
        return this.encodeObject(value, prefix + key + '.');
      } else {
        return [prefix + key, value || ''].map(encodeURIComponent).join('=');
      }
    }).filter(value => value).join('&');
  }

  /**
   * 
   * @param {string} str 
   * @returns 
   */
  decodeObject(str) {
    return str.split('&').filter(s => s).reduce((obj, pair) => {
      let [key, value] = pair.split('=').map(decodeURIComponent);

      if (key.endsWith(']')) {
        let keys = key.split('[');
        let lastKey = keys.pop().replace(']', '');

        keys.reduce((obj, key) => obj[key] = obj[key] || [], obj)[lastKey] = value;
      } else {
        obj[key] = value;
      }

      return obj;
    }, {});
  }

  serialize(obj, prefix) {
    let result = [];

    for (let [key, value] of Object.entries(obj)) {
      let formattedKey = prefix ? prefix + "[" + key + "]" : key;

      result.push((value !== null && typeof value === "object") ?
        this.serialize(value, formattedKey) :
        encodeURIComponent(formattedKey) + "=" + encodeURIComponent(value));
    }

    return result.join("&");
  }

  formSerializeObject(form, base) {
    return form.serializeArray().reduce((obj, input) => {
      let arrMatch = input.name.match(/^([^\.]*)\.([\s\S]*)/);

      if (arrMatch) {
        obj[arrMatch[1]] = obj[arrMatch[1]] || {};
        obj[arrMatch[1]][arrMatch[2]] = input.value;
      } else {
        if (obj[input.name] !== undefined)
          if (obj[input.name] instanceof Array)
            obj[input.name].push(input.value);
          else
            obj[input.name] = [obj[input.name], input.value];
        else
          obj[input.name] = input.value;
      }

      return obj;
    }, base || {});
  }

  decodeDeviceParams(objectParams, ref) {
    var report = objectParams.trim().split("\x00");
    var dataDevice = [];
    dataDevice.VIN = "";
    var allDeviceParameters = [];
    $.each(report, function (i, line) {
      if (line.split(":")[0] == "VIN") { dataDevice.VIN = line.split(":")[1]; }

      if (line.split(":")[0] == "PARAMS") {
        var parameters = line.split(":")[1].split(",");
        $.each(parameters, function (i, param) {
          var currentParams = param.split("-");
          if (currentParams.length > 1) {
            for (let i = currentParams[0]; i - 1 < currentParams[1]; i++) {
              allDeviceParameters.push(parseInt(i));
            }
          }
          else {
            allDeviceParameters.push(parseInt(currentParams[0]));
          }
        });
      }

      if (line.split(":")[0] == "VINORIGIN") {
        dataDevice.VINORIGIN = line.split(":")[1];
      }

    });
    dataDevice.PARAMS = allDeviceParameters;
    return dataDevice;
  }

  decodeAccumulators(accumulatorsStr) {
    return accumulatorsStr
      .split(' ')
      .map(line => line.split(':'))
      .filter(([accum, value]) => accum)
      .reduce((list, [accum, value]) => (list[accum] = parseInt(value), list), {});
  }

  /**
   * 
   * @param {number} duration 
   * @param {moment.unitOfTime.DurationConstructor} [unit="hours"] default: hours
   * @param {string} [format="HH:mm:ss"] default: HH:mm:ss
   * @param {boolean} [trim=false] default: false
   * @returns {string}
   */
  duration(duration, unit = 'hours', format = 'HH:mm:ss', trim = false) {
    return moment.duration(parseFloat(duration), unit).format(format, { trim: trim });
  }

  durationHumanize(duration, unit = 'hours', showUnit = true) {
    let dur = moment.duration(parseFloat(duration), unit);

    if (Math.abs(dur.asSeconds()) >= 45) {
      let humanizeDur;
      if (Math.abs(dur.asDays()) >= 2) {
        humanizeDur = Math.abs(Math.round(dur.asDays())) + (showUnit ? ' ' + i18n.__DAYS.toLowerCase() : '');
      } else {
        humanizeDur = dur.humanize().replace(/^[A-Za-z]+/, '1');
      }

      return (Math.sign(duration) < 0 ? '-' : '') + humanizeDur;
    } else {
      return dur.humanize();
    }
  }

  dateFormat(date, format = 'YYYY-MM-DD HH:mm') {
    let momentDate;

    momentDate = isNaN(date)
      ? moment.utc(date)
      : date instanceof moment
        ? date.utc()
        : moment.unix(date).utc();

    return momentDate.local().format(format);
  }

  isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  notify(obj, defaultType, ...args) {
    let TOASTR_TYPE_MESSAGE = {
      success: i18n.__CONFIRMATION,
      error: i18n.__ERROR,
      info: i18n.__LOADING,
      warning: i18n.__WARNING
    };

    let message = '';
    let type = defaultType;

    if (typeof obj == 'string') {
      message = obj;
    } else {
      if (obj.message) {
        let entry = Object.entries(obj.message)[0];

        type = entry[0];
        message = entry[1];
      } else if (obj.warning) {
        type = 'warning';
        message = obj.warning;
      } else {
        message = obj.error || obj.name;
      }
    }

    toastr[type](i18n['_$' + message](...args), TOASTR_TYPE_MESSAGE[type]);
  }

  notifyError(err) {
    if (err) {
      console.error(err);

      let message = 'AN_ERROR_HAS_OCCURRED';
      let type = 'error';

      if (err.responseJSON) {
        this.notify(err.responseJSON, type);
      } else if (typeof err == 'string') {
        this.notify(err, type);
      } else if (err instanceof (typeof ValidationError !== 'undefined' ? ValidationError : Ingtech.ValidationError)) {
        this.notify(err.message, type, err.field || undefined);
      } else {
        this.notify(message, type);
      }
    }
  }

  alertError(err) {
    if (err) {
      console.error(err);

      let message = 'AN_ERROR_HAS_OCCURRED';

      if (err.responseJSON) err = err.responseJSON;

      if (typeof err == 'string' && err) {
        message = err;
      } else if (typeof err.message == 'string' && err.message) {
        message = err.message;
      } else if (typeof err.error == 'string' && err.error) {
        message = err.error;
      }

      eModal.alert({
        title: i18n.__ERROR,
        message: i18n['__' + message],
        buttons: [{ text: i18n.__CLOSE, close: true, style: 'default' }]
      });
    }
  }

  iconDom(collection, icon, size = 'sm', color = null, tooltip = '', inside = 0, dataText) {
    return `
    <i class="icon-${size} ${color ? `icon-${color}` : ''} icon-${collection}-${icon}" data-toggle="tooltip" title="${tooltip}" data-container="body"${dataText ? ` data-text="${dataText}"` : ''}>
      <span style="display: none;">${inside}</span>
    </i>
  `;
  }

  iconButtonDom(collection, icon, size = 'sm', name = null, color = null, tooltip = '', inside = 0, linkStyle = null) {
    return `
    <a href="javascript:;" class="icon-button"${name ? `name="${name}"` : ''}${linkStyle ? ` style="${linkStyle}"` : ''}>
      <i class="icon-${size}${color ? ` icon-${color}` : ''} icon-${collection}-${icon}" data-toggle="tooltip" title="${tooltip}" data-container="body">
        <span style="display: none;">${inside}</span>
      </i>
    </a>
  `;
  }

  iconUrlDom(collection, icon, size = 'sm', color = null, url = null, newTab = false, tooltip = '', inside = 0) {
    return `
    <a href="${url}" class="icon-button"${newTab ? 'target="_blank"' : ''}>
      <i class="icon-${size} ${color ? `icon-${color}` : ''} icon-${collection}-${icon}" data-toggle="tooltip" title="${tooltip}" data-container="body">
        <span style="display: none;">${inside}</span>
      </i>
    </a>
  `;
  }

  iconDomPopover(collection, icon, size = 'sm', color = 'dark', text = '', inside = 0) {
    return `
    <i href="javascript:;" class="icon-${size} icon-${color} icon-${collection}-${icon}" data-toggle="popover" data-content="${text}" data-container="body" data-trigger="hover" data-placement="top">
      <span style="display: none;">${inside}</span>
    </i>
  `;
  }


  jurisdictionsByCountries(filterCountry, excludeCountry) {
    let jurisdictions = {};

    let tmpCountry;
    for (let code in i18n.JURISDICTIONS) {
      if (code.length == 3) {
        if (tmpCountry && filterCountry) return jurisdictions;

        if (!filterCountry || filterCountry == code) {
          tmpCountry = code;
          jurisdictions[tmpCountry] = [];
        }

        if (excludeCountry) continue;
      }

      if (tmpCountry) {
        jurisdictions[tmpCountry].push(code);
      }
    }

    return jurisdictions;
  }

  /**
   * 
   * @param {string} name 
   * @param {*} data 
   * @param {*} init 
   * @returns {JQuery<HTMLElement>}
   */
  model(name, data = {}, init = null) {
    if (!this[_models][name]) {
      console.error(`Model ${name} not found`);
      return $()
    }

    const html = this[_models][name].replace(/{{(.*?)}}/g, (match, key) => {
      let value;

      if (key[0] == '=') {
        key = key.substr(1);

        value = eval(`data.${key}`);
      } else {
        value = eval(key)
      }

      return value;
    })


    let model = $(html);

    for (let [key, value] of Object.entries(data)) {
      let input = model.input(key);

      if (['INPUT', 'SELECT', 'TEXTAREA'].includes(input.prop('tagName'))) {
        input.val(value).change();
      } else if (input.prop('tagName') == 'CHECKBOX') {
        input.prop('checked', !!value);
      } else {
        input.html(value);
      }
    }

    if (init instanceof Function) {
      init(model, data);
    }

    return model;
  }

  async dataTableCheckbox(ev, dt, row, multiple = false) {
    let checkbox = $(ev.target).prev().isCheckbox() ? $(ev.target).prev() : $(ev.target).find('input[type=checkbox]');

    if ($(row).find('.row-select[disabled]').length > 0 || $(row).isDisabled()) {
      ev.preventDefault();
      ev.stopPropagation();
      return true;
    }

    if (checkbox.length > 0 || multiple) {
      if (ev.shiftKey) {
        // Un petit hack pour mettre le checkbox dans le bon état
        if ($(ev.target).prev().isCheckbox()) {
          ev.preventDefault();

          checkbox.prop('checked', $(row).hasClass('active'));
        }
      } else {
        ev.preventDefault();
        ev.stopPropagation();

        await this.sleep();

        dt.row(row).select(!$(row).hasClass('active'));
        dt.context[0]._select_lastCell = {
          row: dt.row(row).index()
        };
      }
    }

    return checkbox.length > 0 || ev.shiftKey || ev.ctrlKey;
  }
  dataTableCheckboxUpdate(nodes, select) {
    nodes.each(node => {
      let checkbox = $(node).find('.row-select');

      // if (checkbox.prop('disabled'))
      checkbox.prop('checked', select);
    });
  }
  dataTableCheckboxInit(dt) {
    let selectAll = $(dt.table().header()).find('.row-select-all, #row-select-all');

    dt.on('select', (e, dt, type, indexes) => {
      let nodes = dt.rows(indexes).nodes();
      // e.preventDefault()
      this.dataTableCheckboxUpdate(nodes, true);
    });

    dt.on('deselect', (e, dt, type, indexes) => {
      let nodes = dt.rows(indexes).nodes();
      this.dataTableCheckboxUpdate(nodes, false);

      selectAll.prop('checked', false);
    });

    selectAll.change(function (ev) {
      let checked = $(this).prop('checked');
			let indexes = new Set(dt.rows({ "search": "applied" }).indexes().toArray());

      dt.rows((index, data, row) => {
				return indexes.has(index) && !$(row).isDisabled();
			}).select(checked);
    });
  }

  dataTableCheckboxDisabled(dt, disabled) {
    $(dt.table().container()).find('.row-select, .row-select-all').prop('disabled', disabled);
    $(dt.table().container()).find('[role="row"].disabled .row-select').prop('disabled', true);
  }

  dataTableToggleColumnDisplay(dt) {
    const columnButtons = dt.columns().header()
      .toArray()
      .map((column, index) => {
        if ($(column).hasClass('noVis')) {
          return null;
        }

        const $col = $(column).clone();
        $col.find('span').remove();
        const title = $col.text().trim()
          || $(column).text().trim()
          || 'Column ' + (index + 1);

        return {
          text: `
            <label class="i-checks i-checks-dark" style="font-weight: 400;">
              <input type="checkbox" ${dt.column(index).visible() ? 'checked' : ''}>
              <i></i>
              ${title}
            </label>
          `,
          className: '',
          action: (node, ev) => {
            const checkbox = $(node).find('input[type=checkbox]');
            const isVisible = dt.column(index).visible();

            dt.column(index).visible(!isVisible);
            checkbox.checked(!isVisible);

            // update tooltips
            dt.rows().nodes().toJQuery().find('[title]').tooltip({
              container: 'body'
            });

            // prevent menu close
            ev.stopPropagation()
          }
        };
      });

    const filteredColumnButtons = columnButtons.filter(button => button !== null);

    dt.button().add(0, {
      extend: 'dropDown',
      text: i18n.__EDIT_COLUMNS,
      className: "btn btn-default dropdown-toggle btn-dt",
      buttons: filteredColumnButtons
    });
  }




  /**
   *
   * @template T
   * @param {T} a
   * @param {T} b
   * @param {[keyof T, 'asc'|'desc', 'auto'|'string'|'number'|'date'][]} rule
   * @returns {number}
   */
  advCompare(a, b, rules) {
    for (let [key, order, type = 'auto', enumeration] of rules) {
      if (order instanceof Function) {
        let comparison = order(key ? a[key] : a, key ? b[key] : b);

        if (comparison != 0) return comparison;
      }
      else {
        if (type == 'auto') {
          if (!isNaN(a[key]) || !isNaN(b[key])) type = 'number';
          else if (!!(new Date(a[key])).getTime() || !!(new Date(b[key])).getTime()) type = 'date';
          else type = 'string';
        }

        let comparison = 0;
        switch (type) {
          case 'string':
            comparison = a[key].localeCompare(b[key]);
            break;
          case 'number':
            comparison = a[key] - b[key];
            break;
          case 'date':
            comparison = new Date(a[key]).getTime() - new Date(b[key]).getTime();
            break;
          case 'enum':
            comparison = (enumeration[a[key]] || 0) - (enumeration[b[key]] || 0);
            break;
          default:
            break;
        }

        comparison = Math.sign(comparison) * (order == 'desc' ? -1 : 1);

        if (comparison != 0) return comparison;
      }
    }

    return 0;
  }

  /**
   *
   * @template T
   * @param {T[]} array
   * @param {[keyof T, 'asc'|'desc', 'auto'|'string'|'number'|'date'][]} rules [key, order, type = 'auto']
   * @return {T[]}
   */
  advSort(array, rules) {
    for (let rule of rules) {
      if (rule[2] instanceof Array) {
        rule[3] = rule[2];
        rule[2] = 'enum';
      }

      if (rule[2] == 'enum' && rule[3] instanceof Array) {
        rule[3] = Array.from(rule[3].entries()).reduce((d, [i, k]) => (d[k] = i + 1, d), {});
      }
    }

    let sortedArray = array.sort((a, b) => {
      return this.advCompare(a, b, rules);
    });

    return sortedArray;
  }


  /**
   *
   * @template T
   * @param {T[]} array
   * @param {[keyof T, 'asc'|'desc', 'auto'|'string'|'number'|'date'][]} rules [key, order, type = 'auto']
   * @return {T[]}
   */
  advSortFirst(array, rules) {
    for (let rule of rules) {
      if (rule[2] instanceof Array) {
        rule[3] = rule[2];
        rule[2] = 'enum';
      }

      if (rule[2] == 'enum' && rule[3] instanceof Array) {
        rule[3] = Array.from(rule[3].entries()).reduce((d, [i, k]) => (d[k] = i + 1, d), {});
      }
    }

    let first;

    for (let element of array) {
      if (!first) {
        first = element;
      } else {
        if (this.advCompare(element, first, rules) < 0) {
          first = element;
        }
      }
    }

    return first;
  }

  /**
   *
   *
   * @param {{longitude: number, latitude: number}|[number,number]} avl obj or [lng,lat]
   * @param {string} [field='shortAddress']
   * @param {*} [finalCallback=null]
   * @return {*} 
   */
  async reverseGeo(avl, field = 'shortAddress', finalCallback = null) {
    let longitude = this.round(avl instanceof Array ? avl[0] : avl.longitude, 4);
    let latitude = this.round(avl instanceof Array ? avl[1] : avl.latitude, 4);
    let lngLat = longitude + ',' + latitude;

    this[_geoMatrix][field] = this[_geoMatrix][field] || {};

    if (longitude == 0 && latitude == 0) return Promise.reject();
    if (isNaN(longitude) && isNaN(latitude)) return Promise.reject();

    if (this[_geoMatrix][field][lngLat]) {
      return this[_geoMatrix][field][lngLat];
    }
    else {
      if (!this[_geoBuffer]) {
        this[_geoBuffer] = [];

        this.sleep(0).then(() => {
          let callbacks = this[_geoBufferCb];
          let buffer = this[_geoBuffer];

          this[_geoBufferCb] = [];
          this[_geoBuffer] = null;

          $.post(`/json/geocoder/bulk/reverse?count=1&fields=${field}`, { coordinates: buffer }).then(addresses => {
            for (let i = 0; i < addresses.length; i++) {
              let address = addresses[i];
              let [resolve, reject] = callbacks[i];

              if (address && address[0]) {
                let fields = field.split(',').map(field => field.trim());

                resolve(fields.map(field => address[0][field]).join(', '));
              } else {
                reject();
              }
            }

            if (finalCallback instanceof Function) {
              finalCallback();
            }
          }).catch(err => {
            for (let [resolve, reject] of callbacks) {
              reject(err);
            }
          });
        });
      }

      this[_geoBuffer].push([longitude, latitude]);

      return new Promise((resolve, reject) => {
        this[_geoBufferCb].push([(address => {
          this[_geoMatrix][field][lngLat] = address;

          resolve(address);
        }), reject]);
      });
    }
  }


  getLocation() {
    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          resolve(position.coords);
        }, (error_message) => {
          reject(new Error('An error has occurred while retrieving location'));
        });
      } else {
        reject(new Error('geolocation is not enabled on this browser'));
      }
    });
  }



  async getReversedLocation(field = 'shortAddress', finalCallback = null) {
    let location = await this.getLocation();

    return this.reverseGeo(location, field, finalCallback);
  }


  validEmail(email) {
    if (typeof email != 'string') return false;

    email = email.trim();

    if (email.length == 0 || email > 255) return false;
    if (!email.match(/^[\w\.-]+@[\w\.-]+\.\w{2,4}$/)) return false;

    return true;
  }


  onResize(callback, delay = 1000) {
    let timer;

    $(window).on('resize', async () => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(async () => {
        timer = null;

        if (callback instanceof Function) callback();
      }, delay);
    });
  }


  toCamel(str) {
    return str
      .replace(/^\w/, $1 => $1.toLowerCase())
      .replace(/([-_ ][a-zA-Z0-9])/ig, ($1) =>
        $1.toUpperCase()
          .replace(/[-_ ]/, ''));
  }


  removeAccents(str) {
    if (str.normalize) {
      return str + ' ' + str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    }

    return str;
  }

  normalize(str, lower = true) {
    const normalized = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    return lower ? normalized.toLowerCase() : normalized;
  }

  normalizeCompare(a, b) {
    return this.normalize(a) == this.normalize(b);
  }

  searchIn(array, query, attributes) {
    let queries = (query ? query.toLowerCase() : '').split(' ');

    return array.filter(obj => {
      if (typeof obj == 'string') {
        return this.removeAccents(obj).toLowerCase().search(query) >= 0;
      } else {
        for (let [key, value] of Object.entries(obj)) {
          if (typeof value != 'string') continue;
          if (attributes && attributes.indexOf(key) == -1) continue;

          let valid = true;
          for (let query of queries) {
            if (this.removeAccents(value).toLowerCase().indexOf(query) == -1) {
              valid = false;
            }
          }

          if (valid) return true;
        }
      }
    });
  }

  join(arr, separator, ...lastSeparator) {
    let str = '';
    separator = separator || ', ';
    lastSeparator = lastSeparator.length ? lastSeparator : [` ${i18n.__AND} `];

    if (!arr || arr.length == 0) return;

    for (let i = 0; i < arr.length - lastSeparator.length - 1; i++)
      str += arr[i] + separator;

    let start = arr.length - lastSeparator.length - 1;
    for (let i = Math.max(start, 0); i < arr.length - 1; i++)
      str += arr[i] + lastSeparator[i - start];

    str += arr[arr.length - 1];

    return str;
  }

  strMax(str, max, end = '...') {
    if (str.length > max) {
      return str.substr(0, max) + '...';
    } else {
      return str;
    }
  }


  strMatch(string, match) {
    let formattedString = this.removeAccents(string.toLowerCase());
    let formattedMatch = match.toLowerCase();

    for (let subMatch of formattedMatch.split(' ')) {
      if (formattedString.search(subMatch) >= 0) {
        return true;
      }
    }

    return false;
  }

  sanitizeHTML(text) {
    var element = document.createElement('div');
    element.innerText = text;
    return element.innerHTML;
  }

  // call this to Disable
  disableScroll() {
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
  }

  // call this to Enable
  enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
  }

  asciiToHex(str) {
    let arr = [];

    for (let n = 0, l = str.length; n < l; n++) {
      let hex = Number(str.charCodeAt(n)).toString(16).toUpperCase();
      arr.push(hex);
    }

    return arr.join('');
  }


  searchObject(url) {
    url = url || location;

    return util.decodeObject(url.search.slice(1));
  }


  escapeHtml(string) {
    return String(string).replace(/[&<>"'`=\/]/g, function (s) {
      return entityMap[s];
    });
  }


  xor(a, b) {
    return (a || b) && !(a && b);
  }


  dot(color, tooltip, inside, dataText) {
    return `<span class="dot-${color}" data-toggle="tooltip" title="${tooltip}" data-container="body"${dataText ? ` data-text="${dataText}"` : ''}>${inside}</span>`;
  }


  ajaxProgress(query, start, progress) {
    let started = false;
    let lastProgress = 0;
    let finished = false;

    start = start || query.start;
    progress = progress || query.progress;

    if (query.toastr) {
      let progressBarId = 'progress_bar_' + moment().unix();
      let progressBar = `
      <div id="${progressBarId}" class="progress" style="margin: 10px 10px 5px 0px"
            data-toggle="tooltip" title="0%">
        <div id="${progressBarId}-bar" class="progress-bar" role="progressbar" aria-valuenow="0"
              aria-valuemin="0" aria-valuemax="100" style="width:0%">
        </div>
      </div>
    `;

      query.toastr.element = toastr.info(query.toastr.message + progressBar, query.toastr.title || i18n.__LOADING, {
        timeOut: 0, extendedTimeOut: 0, closeButton: false, tapToDismiss: false
      });

      $(`#${progressBarId}`).tooltip();

      let totalSteps = 0;

      let oldStart = start;
      start = (steps, additionalInfo) => {
        if (oldStart instanceof Function) oldStart(totalSteps, additionalInfo);

        totalSteps = steps;
      };

      let oldProgress = progress;
      progress = (progressStep, additionalInfo) => {
        if (oldProgress instanceof Function) oldProgress(progressStep, additionalInfo);

        $(`#${progressBarId}-bar`).css('width', (progressStep / totalSteps) * 100 + '%');

        let message = this.round(
          (progressStep / totalSteps) * 100,
          query.progressDecimal || 0,
          query.progressToFixed || false
        ) + '%' + (additionalInfo && additionalInfo.tooltipMessage ?
          ` - ${i18n.has(additionalInfo.tooltipMessage) ? i18n.get(additionalInfo.tooltipMessage) : additionalInfo.tooltipMessage}` : '');

        $(`#${progressBarId}`).attr('data-original-title', message);
        $(`#${$(`#${progressBarId}`).attr('aria-describedby')}`).find('.tooltip-inner').text(message);
      };
    }

    return new Promise((resolve, reject) => {
      $.ajax({
        url: query.url,
        method: query.method,
        data: query.data,
        query: query.query,
        xhrFields: {
          onprogress: function (e) {
            let response = e.currentTarget.response.split('\n').map(line => {
              try {
                return JSON.parse(line);
              } catch (err) {
                return {};
              }
            });

            let error = response.find(line => line.error);
            if (error) return reject(error);

            if (!started) {
              let startResponse = response.find(line => line.type == 'start');

              if (startResponse) {
                started = true;

                if (start instanceof Function) {
                  start(startResponse.totalSteps, startResponse.additionalInfo);
                }
              }
            }

            let progressResponse = response.filter(line => line.type == 'step');

            if (progressResponse.length > 0) {
              let lastProgressResponse = progressResponse[progressResponse.length - 1];

              if (lastProgressResponse.progressStep > lastProgress) {
                lastProgress = lastProgressResponse.progressStep;

                if (progress instanceof Function) {
                  progress(lastProgressResponse.progressStep, lastProgressResponse.additionalInfo);
                }
              }
            }

            let endResponse = response.find(line => line.type == 'end');
            if (endResponse) {
              resolve(endResponse.content);

              finished = true;
            }
          }
        }
      }).then(result => {
        if (!finished) {
          reject(new Error('PROCESS_HAS_BEEN_INTERRUPTED'));
        }
      }).catch((err) => {
        reject(err);
      }).always(() => {
        if (query.toastr) {
          toastr.clear(query.toastr.element);
        }
      });
    });
  }

  /**
   * @template T
   * @param {T} value
   * @param {T} defaultValue
   * @returns {T}
   */
  default(value, defaultValue) {
    return typeof value == 'undefined' ? defaultValue : value;
  }

  /**
   * @param {number} bits
   * @returns {number}
   */
  bitsToInt32(bits) {
    return bits & 0xFFFFFFFF;
  }

  /**
 * @param {Number} value
 * @param {Number} [bitCount = 0] number of bits to use for the binary representation of `value`.
 * @param {Number} [base = 2] base of the returned string
 * @param {Boolean} [round = false] if `true`, `value` will be rounded before conversion. else, it will be floored.
 * 
 * @returns {String} binary representation of the two's complement of `value`.
 */
  twosComplement(value, bitCount, base = 2, round = false) {
    let binaryStr;

    value = round ? Math.round(value) : Math.floor(value);

    if (value >= 0) {
      let twosComp = value.toString(base);
      binaryStr = this.padAndChop(twosComp, '0', (bitCount || twosComp.length));
    } else {
      binaryStr = (base ** bitCount + value).toString(base);
      if (Number(binaryStr) < 0) {
        return undefined
      }
    }

    return `${binaryStr}`;
  }

  /**
 * @param {String} str
 * @param {String} padChar
 * @param {Number} length
 */
  padAndChop(str, padChar, length) {
    return (Array(length).fill(padChar).join('') + str).slice(length * -1);
  }


  /**
   * 
   * @param {number} percent 
   * @param {[cap: number, color: string][]} colorCode 
   * @returns 
   */
  getColor(percent, colorCode) {
    if (percent == null || isNaN(percent)) return;

    for (let [cap, color] of colorCode) {
      if (percent <= cap) {
        return color;
      }
    }
    return colorCode[colorCode.length - 1][1];
  }

  /**
   * 
   * @param {string[][]} csv 
   * @param {string?} lang 
   * @returns {string}
   */
  arrayToCsv(csv) {
    const lang = i18n.language
    let delimiter = lang === 'en' ? ',' : ';';

    const content = csv.map(line =>
      line.map(field =>
        `"${`${field}`.replace(/"/g, '""')}"`
      ).join(delimiter)
    ).join('\n');

    return '\ufeff' + content;
  }

  /**
   * @template
   * @param {string} name 
   * @param {() => T} callback 
   * @returns {Promise<T>}
   */
  async singleCall(name, callback) {
    if (this[_singleCallback][name]) {
      return new Promise((resolve, reject) => {
        this[_singleCallback][name].push([resolve, reject]);
      });
    }

    this[_singleCallback][name] = [];

    try {
      const result = await callback();

      for (let [resolve] of this[_singleCallback][name]) {
        resolve(result);
      }

      return result;
    } catch (err) {
      for (let [, reject] of this[_singleCallback][name]) {
        reject(err);
      }

      throw err;
    } finally {
      delete this[_singleCallback][name];
    }
  }

  streetViewLink(latitude, longitude, heading = 0, pitch = 0) {
    return `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${latitude},${longitude}${heading ? `&heading=${this.round(heading, 1)}` : ''}${pitch ? `&pitch=${pitch}` : ''}`;
  }

  /**
   * 
   * @param {number} latitude 
   * @param {number} longitude 
   * @param {number} [heading=0] degrees
   * @param {number} [pitch=0]
   */
  openStreetView(latitude, longitude, heading = 0, pitch = 0) {
    return window.open(this.streetViewLink(latitude, longitude, heading, pitch), 'Street View', 'width=560,height=340')
  }

  hashCode(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      var char = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash).toString(16);
  }

  /**
   * Buffer data for a certain duration
   * 
   * @template T
   * @param {T} contant
   * @param {number?} duration
   * @param {boolean?} fixDuration
   * @return {Promise<false | T[]>}
   */
  buffer(data, duration, fixDuration = false, uniqueId = null) {
    const stackKey = this.hashCode(new Error().stack) + (uniqueId ? `_${uniqueId}` : '');

    this[_bufferStack][stackKey] = this[_bufferStack][stackKey] || { data: [], duration: duration, resolves: [], timeout: null };
    const stack = this[_bufferStack][stackKey];

    if (stack.timeout && !fixDuration) {
      clearTimeout(stack.timeout);
      stack.timeout = null;
    }

    if (!stack.timeout) {
      stack.timeout = setTimeout(() => {
        for (let i in stack.resolves)
          stack.resolves[i]((i == stack.resolves.length - 1) ? stack.data : false);

        delete this[_bufferStack][stackKey];
      }, duration || 0);
    }

    if (data !== undefined)
      stack.data.push(data);

    return new Promise((resolve) =>
      stack.resolves.push(resolve));
  }

  lerp(start, end, t) {
    return start + this.clamp(t, 0, 1) * (end - start);
  }

  polyLerp(start, end, t, n = 3) {
    return start + this.clamp(t, 0, 1) ** n * (end - start);
  }
}

const entityMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#x2F;',
  '`': ' & #x60; ',
  '=': '&#x3D;'
};

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36

function preventDefault(e) {
  e.preventDefault();
}

const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; return null; }
  }));
} catch (e) { }

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';






/** @type {Util} */
const util = Ingtech && Ingtech.util ? Ingtech.util : new Util();


Ingtech.util = util;
module.exports = util;